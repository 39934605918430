<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleCreateCategory)">
        <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
        <div style="display: grid; grid-template-columns: 6fr 6fr; gap: 40px">
          <ValidationProvider name="Tên hạng mục" rules="required|max:50" v-slot="{ errors }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Tên hạng mục <span style="color: red">*</span></span>
              <el-input size="small" v-model="name" placeholder="Nhập tên hạng mục"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider name="Loại hạng mục" rules="required" v-slot="{ errors }" vid="date">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Loại hạng mục <span style="color: red">*</span></span>
              <el-select v-model="type" placeholder="Chọn loại hạng mục" size="small">
                <el-option
                    v-for="item in typeOptionCategory"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="date" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 30px">
          <span class="page-filter__title">Mô tả</span>
          <el-input size="small" type="textarea" v-model="description" placeholder="Nhập mô tả"/>
        </div>
        <div style="display: flex; justify-content: end; margin-top: 30px; gap: 10px">
          <el-button style="background: #CACACA" @click="closeDialog()">Hủy bỏ</el-button>
          <button
              :disabled="loading"
              style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
          >
            <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
            Xác nhận
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {typeOptionCategory} from "@/view/pages/honors/Model/honorModel";
import {CREATE_HONOR_CATEGORY} from "@/core/services/store/honors/honor.module";

export default {
  props: {
    addCategory: {
      type: Boolean,
      required: true,
      default: false,
    },
    parentId: {
      type: Number,
      required: true,
      default: null,
    }
  },
  data() {
    return {
      name: '',
      type: '',
      description: '',
      loading: false,
      typeOptionCategory: typeOptionCategory
    }
  },
  methods: {
    handleCreateCategory() {
      this.loading = true;
      this.loading = true
      const payload = {
        "honors_program_id": this.parentId,
        "category_name": this.name,
        "honor_category_type": this.type,
        "category_desc": this.description,
      }
      this.$store.dispatch(CREATE_HONOR_CATEGORY, payload).then(() => {
        this.$message.success('Thêm hạng mục thành công');
        this.closeDialog();
        this.$emit('getListCategory');
        this.addCategory = false;
      })
    },
    closeDialog() {
      this.loading = false;
      this.$emit('closeDialog');

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
